import IRule from '@logic/forms/validators/rules/IRule';
import RuleMessage from '@logic/forms/validators/rules/RuleMessage';

import checkPhone from 'google-libphonenumber';
const phoneUtil = checkPhone.PhoneNumberUtil.getInstance();


export default class MinLengthRule implements IRule
{
    private readonly minValue : number;

    constructor (minValue: number) {
        this.minValue = minValue;
    }
    public check (value : any) : boolean
    {
        return value.length >= this.minValue;
    }

    public message () : RuleMessage
    {
        return {
            ru: `Поле не может содержать меньше ${this.minValue} символов`,
            en: `The field cannot contain less than ${this.minValue} characters`,
            lt: `Srityje negali būti mažiau nei ${this.minValue} simbolių`,
            uz: `Maydon ${this.minValue} belgidan kam bo'lmasligi kerak`
        };
    }
}

