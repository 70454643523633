import { KeyboardEvent, useEffect, useId,useRef, useState} from 'react';
import Image from 'next/image';

import registration from '@logic/language/ex/landing/auth/registration';
import labels from '@logic/language/ex/landing/labels/labels';
import LanguagesType from '@logic/language/types/LanguagesType/LanguagesType';
import Country from '@logic/models/Country/Country';
import useOnClickOutside from '@hooks/UseOnClickOutside';

import {useAppSelector} from '@store/hooks/hooks';

import { useSelectKeyboardNavigation } from '../Select/useSelectKeyboardNavigation';

type PropsType = {
    onSelect: (country: Country) => void,
    propCountry?: string
};

/**
 * Компонент выбора страны в поле ввода телефона
 * defaultCountry - код страны выбранной по умолчанию
 * */
export default function PhoneCountriesSelector (props: PropsType) {

    const {onSelect, propCountry} = props;

    const {language} = useAppSelector((state) => state.generalStatesSlice);
    const {site, defaultCounty} = useAppSelector((state) => state.generalStatesSlice);

    const countries = useAppSelector((state) => state.countriesSlice);

    const searchRef = useRef<HTMLInputElement>(null);
    const ariaId = useId();
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLDivElement>(null);
    const listboxRef = useRef<HTMLDivElement | null>(null);

    useOnClickOutside(ref, () => {
        setIsOpen(false);
    }, buttonRef);


    const [selectedCountry, setSelectedCountry] = useState<Country>();

    const [loading, setLoading] = useState<boolean>(false);

    const selectCountry = (country: Country) => {
        setSelectedCountry(country);
        setIsOpen(false);
        onSelect(country);
    };

    useEffect(() => {
        if (countries.loaded) {
            setLoading(false);
            const found = countries.data.find((countryModel) => countryModel.countryCode.alpha2.toUpperCase() === defaultCounty.toUpperCase());
            if (found) selectCountry(found);
        } else {
            setLoading(true);
        }
    }, [countries]);


    const [searchQuery, setSearchQuery] = useState(``);

    const open = () => {
        if (loading || isOpen) return;
        setIsOpen(true);
    };

    useEffect(() => {
        if(isOpen) {
            setTimeout(()=>{
                searchRef!.current!.focus();
            }, 500);
        }
    }, [isOpen]);

    const { handleSpaceOpen, navigateOptions } = useSelectKeyboardNavigation({
        listboxRef,
        isSelectOpened: isOpen,
        toggleOpenFn: () => setIsOpen(prev => !prev)
    });

    const handleCountryKeyDown = (e: KeyboardEvent<HTMLSpanElement>, country: Country) => {
        if (e.key === 'Enter') {
            setIsOpen(false);
            selectCountry(country);
            buttonRef.current?.focus();
        }
    };

    return (
        <>
            <div
                className={`input__phoneCode ${loading && `input__phoneCode_loading`}`}
                ref={buttonRef}
                role='combobox'
                aria-expanded={isOpen}
                aria-controls={ariaId}
                tabIndex={0}
                onKeyDown={handleSpaceOpen}
                aria-label={isOpen ? 
                    labels.phoneCodesClose[language]
                    : labels.phoneCodesOpen[language]}
                onClick={ (e) => setIsOpen(prev => !prev)}
            >
                {!loading && selectedCountry && <>
                    <Image
                        width={22}
                        height={16}
                        className={`input__phoneCodeFlag`}
                        src={'data:image/png;base64,' + selectedCountry?.countryCode.flag ?? ``}
                        alt={labels.imgAlt[language]}/>
                    <span className={`paragraph phone-code`}>{selectedCountry ? selectedCountry.code : ``}</span>
                    <span className={`input__codeList`}/>
                </>}
            </div>
            <div
                ref={ref}
                onKeyDown={navigateOptions}
                className={`input__phoneList  ${isOpen ? `input__phoneCode_show` : ``}`}
            >
                <div className={`input__phoneListSearch`}>
                    <span className={`input__phoneListSearchImage`}/>
                    <input
                        tabIndex={isOpen ? 0 : -1}
                        ref={searchRef}
                        className={`input input__phoneListInput`}
                        placeholder={registration.find[language === LanguagesType.RU ? LanguagesType.RU : LanguagesType.EN] as string}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
                <div 
                    role='listbox'
                    ref={listboxRef}
                    id={ariaId}
                    className={`input__phoneList__content`}>
                    {
                        countries.loaded &&
                        countries.data
                            .filter(country => country.isActive)
                            .filter((country) => country.countryCode.name[LanguagesType.EN].toLowerCase().includes(searchQuery.toLowerCase())
                                    || country.countryCode.name[LanguagesType.RU].toLowerCase().includes(searchQuery.toLowerCase())
                                    || country?.code?.includes(searchQuery))
                            .map((country, index) => (
                                <span 
                                    key={index} 
                                    className={`input__phoneCodeCountry`} 
                                    tabIndex={isOpen ? 0 : -1}
                                    role='option'
                                    aria-selected={country === selectedCountry}
                                    onKeyDown={(e) => handleCountryKeyDown(e, country)}
                                    onClick={() => selectCountry(country)}>
                                    <Image 
                                        width={22}
                                        height={16}
                                        className={`header__flag`}
                                        src={'data:image/png;base64,' + country.countryCode.flag}
                                        alt={labels.flag[language]}/>
                                    <span className={`paragraph paragraph_color_black input__phoneInList`}>
                                        <span>{country.countryCode.name[language === LanguagesType.RU ? LanguagesType.RU : LanguagesType.EN]}</span> {country.code}
                                    </span>
                                </span>
                            ))
                    }
                </div>
            </div>
        </>
    );
}
