import styled from 'styled-components';

import { Breakpoints } from '@src/styledComponents/base/breakpoints';
import { mediaBreakpointDown } from '@src/styledComponents/base/functions';

export default styled.button`
  cursor: pointer;
  background: url("/landing/modalAuth/closeModal.svg") center center no-repeat;
  height: 28px;
  width: 28px;
  background-size: contain;

  ${mediaBreakpointDown(Breakpoints.xMobile)} {
    height: 24px;
    width: 24px;
  }
`;