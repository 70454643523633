const registration = {
    mapHeading: {
        ru: `Регистрация`,
        en: `Sign up`,
        lt: `Registration?`,
        uz: `Ro'yxatdan o'tish`
    },
    mapText: {
        ru: `Уже есть аккаунт?`,
        en: `Already have an account?`,
        lt: `Turite paskyrą?`,
        uz: `Allaqachon hisob bormi?`
    },
    mapLinkText: {
        ru: `Войти`,
        en: `Log in`,
        lt: `Prisijungti`,
        uz: `Kirish`
    },
    mapStepRoleSelection: {
        ru: `Выбор роли`,
        en: `Select your position`,
        lt: `Vaidmens pasirinkimas`,
        uz: `Rolni tanlash`
    },
    mapStepPersonalInfo: {
        ru: `Персональная информация`,
        en: `Personal information`,
        lt: `Asmeninė informacija`,
        uz: `Shaxsiy ma'lumotlar`
    },
    mapStepPhoneConfirmation: {
        ru: `Подтверждение телефона`,
        en: `Verify phone`,
        lt: `Telefono patvirtinimas`,
        uz: `Telefonni tasdiqlash`
    },
    mapStepEmailConfirmation: {
        ru: `Подтверждение E-mail`,
        en: `Verify e-mail`,
        lt: `El. pašto patvirtinimas`,
        uz: `Tasdiqlash elektron pochta`
    },
    roleSelectionHeading: {
        ru: `Выберите роль`,
        en: `Select your position`,
        lt: `Pasirinkite vaidmenį`,
        uz: `Rolni tanlang`
    },
    roleSelectionCardCompany: {
        ru: `Компания`,
        en: `Company`,
        lt: `Įmonė`,
        uz: `Kompaniya`
    },
    roleSelectionCardFreelancer: {
        ru: `Фрилансер`,
        en: `Freelancer `,
        lt: `Frilanseris`,
        uz: `Freelancer`
    },
    buttonNext: {
        ru: `Далее`,
        en: `Next`,
        lt: `Toliau`,
        uz: `Keyingi`
    },
    buttonConfirm: {
        ru: `Подтвердить`,
        en: `Confirm`,
        lt: `Patvirtinti`,
        uz: 'Tasdiqlash'
    },
    personalInfoHeading: {
        ru: `Заполните необходимую информацию`,
        en: `Personal information`,
        lt: `Užpildykite reikiamą informaciją `,
        uz: `Kerakli ma'lumotlarni to'ldiring`
    },
    personalInfoCaption: {
        ru: `* – обязательное поле`,
        en: `* – required field`,
        lt: `* – privalomas laukas`,
        uz: `* – majburiy maydon`
    },
    labelName: {
        ru: `Имя`,
        en: `Name`,
        lt: `Vardas`,
        uz: `Ismi`
    },
    placeholderName: {
        ru: `Имя`,
        en: `Name`,
        lt: `Vardas`,
        uz: `Ismi`
    },
    labelSurname: {
        ru: `Фамилия`,
        en: `Surname`,
        lt: `Pavardė`,
        uz: `Eng ko'p ishlatiladigan`
    },
    placeholderSurname: {
        ru: `Фамилия`,
        en: `Surname`,
        lt: `Pavardė`,
        uz: `Eng ko'p ishlatiladigan`
    },
    labelEmail: {
        ru: `E-mail`,
        en: `E-mail`,
        lt: `Paštas`,
        uz: `Elektron pochta`,
    },
    placeholderEmail: {
        ru: `E-mail`,
        en: `E-mail`,
        lt: `Paštas`,
        uz: `Elektron pochta`,
    },
    labelPhone: {
        ru: 'Телефон',
        en: 'Phone',
        uz: 'Telefon',
        lt: '',
    },
    labelPassword: {
        ru: `Пароль`,
        en: `Password`,
        lt: `Slaptažodis`,
        uz: `Parol`,
    },
    placeholderPassword: {
        ru: `Пароль`,
        en: `Create a password`,
        lt: `Slaptažodį`,
        uz: `Parol`
    },
    labelRepeatPassword: {
        ru: `Повторите пароль`,
        en: `Confirm your password`,
        lt: `Pakartokite slaptažodį`,
        uz: `Parolni takrorlang`
    },
    placeholderRepeatPassword: {
        ru: `Повторите пароль`,
        en: `Create a password`,
        lt: `Pakartokite slaptažodį`,
        uz: `Parolni takrorlang`
    },
    agreementsByClicking: {
        ru: `Нажимая кнопку «Далее» вы даёте согласие с`,
        en: `By clicking the "Next" button, you agree to the `,
        lt: `Spausdami mygtuką „Sekantis žingsnis‟ sutinkate su `,
        uz: `"Keyingi qadam" tugmasini bosish orqali siz rozilik berasiz`
    },
    agreementsPrivacyPolicy: {
        ru: `политикой конфиденциальности,`,
        en: `privacy policy,`,
        lt: `privatumo politika,`,
        uz: `maxfiylik siyosati,`
    },
    agreementsOffer: {
        ru: `офертой`,
        en: `the offer`,
        lt: `pasiūlymu`,
        uz: `Taklif`
    },
    agreementsUserAgreement: {
        ru: `пользовательским соглашением.`,
        en: `user agreement.`,
        lt: `vartotojo sutartis.`,
        uz: `foydalanuvchi shartnomasi.`
    },
    agreementsAnd: {
        ru: `и`,
        en: `and`,
        lt: `ir`,
        uz: `va`
    },
    phoneConfirmationHeading: {
        ru: `Введите код подтвержения`,
        en: `Enter confirmation code`,
        lt: `Įveskite patvirtinimo kodą`,
        uz: `Tasdiqlash kodini kiriting`
    },
    phoneConfirmationTextBefore: {
        ru: `На номер`,
        en: `An SMS with a confirmation code was sent to the number`,
        lt: `Į telefono numerį`,
        uz: `Raqamga`
    },
    phoneConfirmationTextAfter: {
        ru: `отправлено СМС с кодом подтверждения.`,
        en: ``,
        lt: `buvo išsiųsta SMS žinutė su patvirtinimo kodu`,
        uz: `tasdiqlash kodi bilan SMS yuborildi.`
    },
    phoneConfirmationResend: {
        ru: `Отправить код повторно`,
        en: `Resend code`,
        lt: `Išsiųsti pakartotinai`,
        uz: `Kodni qayta yuboring`
    },
    leftSeconds: {
        ru: `Повторно отправить код можно будет через:`,
        en: `Code expires in:`,
        lt: `Iki kodo galiojimo pabaigos liko:`,
        uz: `Kodni qayta yuborishingiz mumkin:`
    },
    emailConfirmationTextBefore: {
        ru: `На почту`,
        en: `A letter was sent to`,
        lt: `Į paštą buvo išsiųstas laiškas`,
        uz: `Pochta orqali`
    },
    emailConfirmationTextAfter: {
        ru: `было отправлено письмо с кодом. Для завершения регистрации введите код из письма.`,
        en: `. To complete your registration, enter the code from email.`,
        lt: `su registracijos patvirtinimo nuoroda. Norėdami baigti registraciją, pereikite į laiške pateiktą nuorodą.`,
        uz: `kod bilan xat yuborildi. Ro'yxatga olishni yakunlash uchun xatdan kodni kiriting.`
    },
    spamMessage: {
        ru: `Если вы не нашли письмо на почте, проверьте папку Спам`,
        en: `Check your Spam folder If you didn't find the email in your inbox`,
        lt: `Jei laiško nerandate savo pašto dėžutėje, patikrinkite brukalo aplanką`,
        uz: `Agar pochta bo'yicha elektron pochta topish, Agar yo'q bo'lsa, spam papkani tekshirish`
    },
    find: {
        ru: `Найти`,
        en: `Find`,
        lt: `Susirasti`,
        uz: `Topish`
    }
};

export default registration;
