import Image from 'next/image';

import { useAppSelector } from '@store/hooks/hooks';

import Button from '@components/common/buttons/Button';

type Props = {
    backgroundClass?: string
};

const RELOAD_TEXT = {
    ru: `Перезагрузить страницу`,
    en: `Reload the page`,
    lt: `Perkrauti puslapį`,
    uz: `Sahifani qayta yuklash`,
};

//TODO добавить ошибку отсутвия интернета
export default function ServerError (props: Props)
{
    const {backgroundClass} = props;

    const reload = () => {
        window.location.reload();
    };

    const {language} = useAppSelector((state) => state.generalStatesSlice);

    return (
        <div className={`serverError ${backgroundClass}`}>
            <div className={`serverError__content`}>
                <div className={`serverError__iconWrapper`}>
                    <Image 
                        width={140}
                        height={140}
                        src={`/icons/sad-icon.svg`} 
                        alt={`Sad icon`}/>
                </div>
                <div className={`serverError__textContentWrapper`}>
                    <div className={`serverError__textContent`}>
                        <h2 className={`serverError__title`}>Ошибка обращения к сервису</h2>
                        <p className={`serverError__description`}>Мы уже устраняем неисправность, попробуйте повторить попытку позже</p>
                    </div>
                </div>
                <div className={`serverError__reloadWrapper`}>
                    <Button onClick={reload}>
                        {RELOAD_TEXT[language]}
                    </Button>
                </div>
            </div>
        </div>
    );
}