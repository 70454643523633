import IRule from '@logic/forms/validators/rules/IRule';
import RuleMessage from '@logic/forms/validators/rules/RuleMessage';

import checkPhone from 'google-libphonenumber';
const phoneUtil = checkPhone.PhoneNumberUtil.getInstance();


export default class PhoneRule implements IRule
{
    public check (value : any) : boolean
    {
        const code = value.country;
        const phone = value.number;
        if(phone === ``) return false;
        try {

            const number = phoneUtil.parseAndKeepRawInput(phone, code);
            const valNumber = `+${number.getCountryCode()}${number.getNationalNumber()}`;
            return phoneUtil?.isValidNumber(number) && valNumber === number.getRawInput();
        }catch(e) {
            return false;
        }
    }

    public message () : RuleMessage
    {
        return {
            ru: `Неверный формат телефона`,
            en: `Phone format is wrong`,
            lt: `Neteisingas telefono numerio formatas`,
            uz: `Telefon raqam xato`,
        };
    }
}

//^\+?[78][-]?([(]\d{3}[)]|\d{3})?-?\d{3}-?\d{2}-?\d{2}$
