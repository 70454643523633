import React, {useEffect, useId, useState} from 'react';
import styled, { css } from 'styled-components';
import { AnimatePresence } from 'framer-motion';

import Country from '@logic/models/Country/Country';

import { Colors } from '@components/styledComponents/base/Colors';
import PhoneCountriesSelector from '@src/common/selectors/PhoneCountriesSelector/PhoneCountriesSelector';

import { baseInputStyles } from '../components/baseInputStyles';
import { InputError } from '../components/InputError/InputError';
import { InputLabel } from '../components/InputLabel/InputLabel';

export type Phone = {
    country: string,
    number: string,
};

export type PhoneNumber = {
    number: string,
    country: Country | null
};

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    placeholder?: string,
    phoneValue: Phone,
    isError?: boolean,
    isValid?: boolean,
    isRequired?: boolean,
    errorMessage?: string,
    label?: string,
    noMessageSpace?: boolean,
    setPhoneValue: (value: Phone) => void;
}

export default function PhoneInput (props: Props) {

    const {
        placeholder,
        phoneValue,
        setPhoneValue,
        isError,
        isValid,
        isRequired,
        errorMessage,
        disabled,
        name,
        label,
        noMessageSpace,
    } = props;

    const [phone, setPhone] = useState<PhoneNumber>({
        number: ``,
        country: null
    });

    const [loading, setLoading] = useState<boolean>(true);

    const id = useId();

    const setCountry = (country: Country) => {
        setPhone((prev) => ({
            ...prev,
            country: country
        }));
    };

    const setPhoneNumber = (number: string) => {
        if(!phone.country) return;
        const clearNumber = number.replace(/[\D]+/g, '');
        setPhone((prev) => ({
            ...prev,
            number: clearNumber
        }));
    };

    const getPhoneWithoutCode = (number: string) : string => {
        if(!phone.country) return ``;
        return ('+' + number).replace(phone.country.code, ``);
    };

    useEffect(() => {
        if(loading && phone.country) {
            setPhoneNumber(getPhoneWithoutCode(phoneValue?.number));
            setLoading(false);
        }
        if(!loading) {
            setPhoneValue({
                number: `${phone.country?.code}${phone.number}`,
                country: phone.country?.countryCode.alpha2 ?? ``
            });
        }
    }, [phone]);

    return (
        <InputWrapper>
            <InputContainer isDisabled={!!disabled}>
                {label && <InputLabel forInput={id} isRequired={isRequired} text={label}/>}

                <PhoneField isLoading={loading}>
                    <InputField
                        name={name}
                        isError={isError}
                        isValid={isValid}
                        autoComplete={'off'}
                        autoFocus={false}
                        type={`text`}
                        placeholder={placeholder ?? ''}
                        value={phone.number}
                        onChange={(e) => setPhoneNumber(e.target.value.replaceAll(' ', ''))}
                        id={id}
                    />
                    <PhoneCountriesSelector
                        propCountry={phoneValue?.country}
                        onSelect={setCountry}
                    />
                </PhoneField>

            </InputContainer>
            <AnimatePresence>
                {isError && errorMessage &&
                  <InputError absolutePosition={noMessageSpace} message={errorMessage} />}
            </AnimatePresence>
        </InputWrapper>
    );
}
;

const InputWrapper = styled.div`
  position: relative;
  height: min-content;
`;

const InputContainer = styled.div<{isDisabled: boolean}>`
  pointer-events: ${({isDisabled}) => isDisabled ? 'none' : 'all'};
  opacity: ${({isDisabled}) => isDisabled ? 0.8 : 1};
  display: grid;
  align-self: flex-start;
  row-gap: 6px;
`;

const PhoneField = styled.div<{isLoading: boolean}>`
  position: relative;
  height: 100%;
  background-color: ${({isLoading}) => isLoading ? Colors.GRAY_100 : Colors.WHITE};
  border-radius: 10px;
  ${({isLoading}) => isLoading && css`
    &*{
        display: none;
    }
  `}
`;

const InputField = styled.input<{isError?: boolean, isValid?: boolean}>`
  ${baseInputStyles};
    padding: 14px;
   padding-left: 100px;
`;