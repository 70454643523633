import { InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { Colors } from '@components/styledComponents/base/Colors';

import CheckIcon from '@public/icons/baseUI/check/check.svg';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    checkColor?: Colors,
    checkHoverColor?: Colors,
    checkMarkColor?: Colors,
    isCheck: boolean,
    isDisabled?: boolean, 
    isIntermediate?: boolean,
    isError?: boolean
};

export const Checkbox = (props: Props) => {

    const {
        checkColor = Colors.EMERALD, 
        checkHoverColor = Colors.NDA,
        checkMarkColor = Colors.WHITE, 
        isCheck, 
        onChange, 
        isIntermediate,
        isError,
        disabled,
        onClick} = props;

    return (
        <CheckboxWrapper
            onClick={onClick}
            checkColor={checkColor}
            checkHoverColor={checkHoverColor}
            checkMarkColor={checkMarkColor}
            isIntermediate={isIntermediate}
            isError={isError}>
            <input 
                type="checkbox"
                checked={isCheck} 
                onChange={onChange}
                disabled={disabled}/>
            {!isIntermediate && <CheckIcon />}
        </CheckboxWrapper>
    );
};

const CheckboxWrapper = styled.span<{
    checkColor: Colors,
    checkHoverColor: Colors,
    checkMarkColor: Colors,
    isIntermediate?: boolean,
    isError?: boolean,
}>`
    --activeColor: ${({checkColor}) => checkColor};
    --hoverColor: ${({checkHoverColor}) => checkHoverColor};

    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;

    input[type="checkbox"] {
        -webkit-appearance: none;
        appearance: none;

        width: 16px;
        height: 16px;
        margin: -3px;
        flex-shrink: 0;
        border: 1px solid ${({isError}) => isError ? Colors.DANGER : Colors.GRAY_600};
        border-radius: 4px;
        outline: none;
        transition: border 0.3s, background-color 0.3s;
        cursor: pointer;
        background-color: ${({isError}) => isError ? 'rgba(251, 118, 118, 0.2);' : 'transparent'};

        &+svg {
            pointer-events: none;
            position: absolute;
            flex-shrink: 0;
            z-index: 1;
            display: block;
            margin: auto;
            width: 16px;
            height: 16px;
            fill: ${({checkMarkColor}) => checkMarkColor};
            opacity: 0;
            visibility: hidden;
            transition: fill 0.3s, opacity 0.3s, visibility 0.3s;
        }

        &:focus {
            outline: 3px solid ${Colors.GRAY_400};
            outline-offset: 0px;
            &:not(:focus-visible) {
                outline: none;
            }
        }

        &:checked {
            border: 1px solid var(--activeColor);
            background-color: var(--activeColor);

            &+svg {
                opacity: 1;
                visibility: visible;
            }
        }

        &:checked:hover {
            border: 1px solid var(--hoverColor);
            background-color: var(--hoverColor);
        }

        &:hover {
            border: 1px solid var(--activeColor);
        }

        &:disabled {
            pointer-events: none;
            border: 1px solid ${Colors.GRAY_300};
            background-color: ${Colors.GRAY_300};
        }

        &:disabled:checked {
            border: 1px solid ${Colors.GRAY_400};
            background-color: ${Colors.GRAY_400};

            &+svg {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    ${({isIntermediate, checkMarkColor}) => isIntermediate && css`
        &::before {
            content: '';
            pointer-events: none;
            position: absolute;
            width: 8px;
            height: 2px;
            background-color: ${checkMarkColor};
        }
    `}
`;